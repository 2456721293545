<template>
  <div class="information">
    <div class="personmain bgpurple mt15 flex-starts">
      <!-- 按钮 -->
      <div class="rightbar bgwhite shadow radius5">
        <div>
          <div class="right" v-for="(i,index) in titleList" :key="index"  @click="titleClick(index,i.domId)">
            <el-tooltip class="item" effect="dark" :content="i.content" placement="right-start">
              <i :class="[i.icon,activeIndex == index ? 'active' : '']" ></i>
            </el-tooltip>
            <b :class="[activeIndex == index ? 'active' : '']">{{i.title}}</b>
          </div>                   
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="scroll-content">
        <!-- 我的课程 -->
        <div class="personal-sty scroll-item" id="kc">
          <span class="persty sty1">我的课程</span>
          <div class="sty-nr">
            <div class="positionleft">
              <span class="sty-nr-titl">
                <em @click="courseClick(0)"  :style="{ backgroundColor: (active == 0 ?'#fff' : '')}">
                  <i class="ico bxico" style="font-style:normal" >进行中</i>
                </em>
                <em @click="courseClick(1)" :style="{ backgroundColor: (active == 1 ?'#fff' : '')}">
                  <i class="ico xxico" style="font-style:normal" >已完成</i>
                </em>
              </span>
            </div>
            <el-collapse v-model="activeNames" class="border0">
              <el-collapse-item name="1">
                <div v-if="myCourseList.length!=0">
                  <ul>
                    <li class="flex-between" v-for="(i,index) in myCourseList" :key="index">
                      <div class="sty-nrleft" >
                        <span style="" class="sty-title" @click="curriculumClick(i.courseId)">
                          <img :src="i.facePic" alt="" style="width: 60px;height: 40px;font-size: 12px;margin-right: 5px;">
                           {{i.courseName}}
                        </span>
                      </div>
                      <div class="sty-nrright">
                        <el-progress :percentage="i.courseProgress"></el-progress>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <el-empty description="暂无课程"></el-empty>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: localStorage.getItem("pc_token"),
      titleList:[
        {icon:'el-icon-notebook-2',title:'我的课程',content:'我的课程',domId:'kc'},
      ],
      myCourseList:[],
      meetingRoomList:[],
      activityList:[],
      certificate:[],
      orderList:[],
      practiceUserPapersList:[],
      examinationuserPapersList:[],
      papersList:[],
      liveRoomList:[],
      integralList:[],
      pageSize:8,
      page:1,
      count:0,
      activeIndex:0,
      activityState:1,
      papernum:1,
      activeName: "second",
      activeNames: ["1"],
      active:0
      // activeColor: '',
    };
  },
  mounted(){
    // this.getMyCourseList()
  },
  methods: {
    // 课程跳转
    curriculumClick(courseId){
      this.$router.push({
          path:'/course/detailList',
          query:{id:courseId}
        })
    },
    titleClick(index,domId){
      this.activeIndex = index
      // 当前窗口正中心位置到指定dom位置的距离
      //页面滚动了的距离
      let height = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; 
      //指定dom到页面顶端的距离
      let dom = document.getElementById(domId);
      let domHeight = dom.offsetTop+1;
      //滚动距离计算
      var S = Number(height) - Number(domHeight);
      //判断上滚还是下滚
      if(S<0){
          //下滚
          S = Math.abs(S);
          window.scrollBy({ top: S, behavior: "smooth" });
      }else if(S==0){
          //不滚
          window.scrollBy({ top: 0, behavior: "smooth" });
      }else{
          //上滚
          S = -S
          window.scrollBy({ top: S, behavior: "smooth" });
      }
    },
    courseClick(val){
      this.active = val
      this.getMyCourseList()
    },
    // 我的课程
    getMyCourseList(){
      this.$api.getMyCourseList({
        token:this.token,
        page: 1,
        limit: 8,
        courseProgress:this.active
      }).then(res => {
        if(res.code == 10000){
          this.myCourseList = res.data.data
        }
      })
    },
   
    trainingClick(val){
      this.activityState = val
      this.getMyActivityList()
    },
    // 分页
    handleCurrentChange(curPage){
      this.page = curPage
      this.queryUserPointsLogList()
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.information {
  margin-bottom: 50px;
  
  .bgpurple {
    background: #fff;
  }
  .personmain {
    padding: 20px;
  }
  .flex-starts {
    display: flex;
    align-items: flex-start;
  }
  .mt15 {
    margin-top: 15px;
  }
  .rightbar {
    position: sticky;
    margin-left: -90px;
    margin-right: 20px;
    margin-top: -20px;
    top: 0;
    width: 70px;
    padding: 5px;
    box-sizing: border-box;
    z-index: 1;
  }
  .right {
      float: right;
      width: 60px;
    }
    .item {
      margin: 4px;
    }
    .active{
      color: #3CE4BC !important;
    }
  .rightbar div {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 5px 0;
    border-bottom: 1px dashed #ece7e7;
}
.rightbar div:last-child{
  border-bottom: 0px dashed #ece7e7;
}
.rightbar div i {
    font-size: 20px;
    color: #333;
}
.rightbar div b {
    font-size: 12px;
    margin-top: 5px;
    color: #333;
}
  @media (max-width: 1500px) {
    .rightbar {
      margin-left: -20px !important;
    }
  }
  .scroll-content{
    width: 100%;
    height: 100%;
    // overflow: scroll;
  }
  .personal-sty {
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 40px 20px 20px;
    margin-top: 15px;
  }
  .sty1 {
    background: url(../../assets/images/personal/course.png) no-repeat;
    background-size: 107px auto;
  }
  .sty2 {
    background: url(../../assets/images/personal/mting.png) no-repeat;
    background-size: 107px auto;
  }
  .sty3 {
    background: url(../../assets/images/personal/peixun.png) no-repeat;
    background-size: 107px auto;
  }
  .sty4 {
    background: url(../../assets/images/personal/zhsu.png) no-repeat;
    background-size: 107px auto;
  }
  .sty5 {
    background: url(../../assets/images/personal/buy.png) no-repeat;
    background-size: 107px auto;
  }
  .sty6 {
    background: url(../../assets/images/personal/xiti.png) no-repeat;
    background-size: 107px auto;
  }
  .sty7 {
    background: url(../../assets/images/personal/shijuan.png) no-repeat;
    background-size: 107px auto;
  }
  .sty8 {
    background: url(../../assets/images/personal/live.png) no-repeat;
    background-size: 107px auto;
  }
  .sty9 {
    background: url(../../assets/images/personal/integral.png) no-repeat;
    background-size: 107px auto;
  }
  .persty {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 29px;
    line-height: 29px;
    padding-left: 15px;
    color: #fff;
  }
  .sty-nr {
    position: relative;
    background: #faf7f8;
    padding: 5px 20px;
  }
  .positionleft {
    position: absolute;
    left: 0;
    top: 10px;
    z-index: 111;
}
.sty-nr-titl {
    display: flex;
    align-content: center;
    align-items: center;
    padding: 10px 20px;
}
.sty-nr-titl em {
    display: flex;
    padding: 3px 5px;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    font-size: 13px;
    
}
.border0 {
    border: none;
    background: none;
}
.ml5 {
    margin-left: 5px;
    color: #ad7a3b;
}
  .shadow {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
  .radius5 {
    border-radius: 5px;
  }
  .bgwhite {
    display: inline-block;
    background-color: #fff;
  }
  .sty-nr ul li {
    background: #fff;
    padding: 8px 15px;
    margin-top: 15px;
}
.sty-nrleft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.sty-title{
  display:flex;
  align-items: center;
  cursor: pointer;
}
.sty-title:hover{
  color: #3CE4BC;
}
.block {
    display: block!important;
}
.sty-nrright {
    width: 40%;
    text-align: right;
}
.page {
  display: inline-block;
  width: 100%;
  text-align: right;
}
.viewpaper {
    float: right;
}
.getscore {
    float: right;
    font-size: 14px;
    margin: 0 10px;
}
.getscore b {
    color: red;
    margin-left: 5px;
    font-weight: 700;
}
.paystatus {
    padding: 0 10px;
    font-size: 15px;
    margin-bottom: 10px;
}
.paystatus-l span {
    margin-right: 15px;
    color: #333;
}
.payli {
    padding: 8px 15px;
    background: #fff;
}
.sty-nrleft a {
    display: flex;
    align-items: center;
}
.pull-r span.mon {
    padding: 5px 15px;
    font-size: 14px;
    color: #666;
}
}
.sty-nr::v-deep .el-collapse-item__header{
  background: none;
  border-bottom: 0px solid #EBEEF5;
}
.sty-nr::v-deep .el-collapse-item__wrap{
  background: none;
}
.sty-nr::v-deep .el-collapse{
  border-top: 0px solid #EBEEF5;
  border-bottom: 0px solid #EBEEF5;
}
</style>